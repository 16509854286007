import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { LoaderCircle } from "lucide-react";

const Game = () => {
  const user = useAuthUser();
  const isSignedIn = useIsAuthenticated();
  const [name, setName] = useState("");
  const { gameId } = useParams(); // Extract gameId from thes URL
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true); // New state for iframe loading

  const handleIframeLoad = () => {
    setIframeLoading(false); // Set iframe loading to false when iframe is loaded
  };

  const run = useCallback(async () => {
    try {
      console.log(gameId);
      const res = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/getGame`,
        {
          username: user.username,
          password: "Ben1441#",
          gameId: gameId,
        }
      );
      console.log(res.data);
      setName(res.data.name);
      setLink(res.data.response);
    } catch (error) {
      console.error("Error fetching game:", error);
      setError(error.message || "Failed to load game");
    }
  }, [gameId, user]);

  useEffect(() => {
    try {
      if (user && isSignedIn) {
        run();
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [user, run, isSignedIn]);

  if (!isSignedIn) {
    return (
      <div className="text-white text-3xl flex items-center justify-center">
        Please Log In or Register to Play
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-white text-3xl flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center gap-10">
      <h1 className="text-4xl font-bold text-white">{name}</h1>
      {iframeLoading && ( // Show spinner while iframe is loading
        <LoaderCircle color="#ffffff" className="w-20 h-20 animate-spin" /> // Replace with your spinner component or styling
      )}
      <iframe
        className="rounded-xl"
        src={link}
        width={1000}
        height={600}
        frameBorder="0"
        title={gameId}
        onLoad={handleIframeLoad} // Call handleIframeLoad when iframe loads
      ></iframe>
    </div>
  );
};

export default Game;
