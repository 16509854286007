import React from "react";
import { StyledHoverableImgMainHome } from "./styles";

const HoverableImgMainHome = ({ src, alt, link }) => {
  return (
    <div>
      <StyledHoverableImgMainHome>
        <a href={link}>
          <img
            src={src}
            alt={alt}
            className="image-card bg-cover w-40 h-auto"
          />
        </a>
      </StyledHoverableImgMainHome>
    </div>
  );
};

export default HoverableImgMainHome;
