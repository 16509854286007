import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as FLOW } from "../../../../assets/images/Frame (49).svg";
import AccountPageTitle from "../Common/AccountPageTitle";
import AccountTabs from "../Common/AccountTabs/AccountTabs";
import { StyleProfile } from "../Profile/styles";
import ReferralBanner from "./ReferralBanner";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const tabs = [
  { url: "/account/referrals/codes", label: "Referral Codes" },
  { url: "/account/referrals/users", label: "Total Referred Users" },
];

const Referrals = () => {
  const { tab } = useParams();
  const [referralCodes, setReferralCodes] = useState([]);
  const [referredUsers, setReferredUsers] = useState([]);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [lastCodeCreatedAt, setLastCodeCreatedAt] = useState(null);
  const user = useAuthUser();

  const [referralCode, setReferralCode] = useState("");
  const [applyMessage, setApplyMessage] = useState("");

  useEffect(() => {
    fetchReferralData();
  }, [user]);

  const fetchReferralData = async () => {
    if (user?.username) {
      try {
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
        );
        if (res.data) {
          setReferralCodes(res.data.referralCodes);
          setReferredUsers(res.data.referredUsers);
          setTotalReferrals(res.data.referredUsers.length);
          setTotalCommission(res.data.totalCommission);
          if (res.data.referralCodes.length > 0) {
            setLastCodeCreatedAt(
              new Date(
                res.data.referralCodes[
                  res.data.referralCodes.length - 1
                ].createdAt
              )
            );
          }
        }
      } catch (error) {
        console.error("Error fetching referral data:", error);
        toast.error("Failed to fetch referral data");
      }
    }
  };

  const handleCreateReferral = async (referralCode) => {
    try {
      const url = `http://${process.env.REACT_APP_API_URL}:5005/create-referral-code`;
      const response = await axios.post(url, {
        referralCode,
        userId: user.id,
        username: user.username,
      });

      if (response.data.success) {
        toast.success("Referral code created successfully");
        setReferralCodes([
          ...referralCodes,
          {
            code: response.data.newReferralCode,
            expiresAt: response.data.expiresAt,
            createdAt: new Date(),
          },
        ]);
        setLastCodeCreatedAt(new Date());
      } else {
        toast.error("Failed to create referral code");
      }
    } catch (error) {
      console.error("Error creating referral code:", error);
      toast.error(`Failed to create referral code: ${error.message}`);
    }
  };

  const isCreateButtonDisabled = () => {
    if (!lastCodeCreatedAt) return false;
    const now = new Date();
    const timeDiff = now - lastCodeCreatedAt;
    return timeDiff < 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  };

  const getTimeUntilEnabled = () => {
    if (!lastCodeCreatedAt) return "";
    const now = new Date();
    const timeDiff = 24 * 60 * 60 * 1000 - (now - lastCodeCreatedAt);
    if (timeDiff <= 0) return "";
    const hours = Math.floor(timeDiff / (60 * 60 * 1000));
    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
    return `(Available in ${hours}h ${minutes}m)`;
  };

  const referralLink = `http://${process.env.REACT_APP_API_URL}:3000/register?ref=`;

  const handleApplyReferral = async (e) => {
    e.preventDefault();
    setApplyMessage("");

    try {
      const response = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/set-referrer`,
        {
          username: user.username,
          referralCode: referralCode,
        }
      );

      if (response.data.success) {
        setApplyMessage("Referral code applied");
        toast.success("Referral code applied successfully");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setApplyMessage(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setApplyMessage("An error occurred. Please try again.");
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const renderTabContent = () => {
    if (tab === "codes") {
      return (
        <>
          <Formik
            initialValues={{ referralCode: "" }}
            validationSchema={Yup.object({
              referralCode: Yup.string()
                .required("Required")
                .min(3, "Must be at least 3 characters"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleCreateReferral(values.referralCode);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="bg-[rgba(203,215,255,0.03)] rounded-lg w-4/5 h-16 flex flex-row items-center justify-between gap-3 p-3">
                <Field
                  name="referralCode"
                  type="text"
                  className="w-full h-full bg-[#1a1d29] px-2 rounded-md outline-amber-400"
                  placeholder="Create new referral code"
                />
                {errors.referralCode && touched.referralCode ? (
                  <div className="text-red-500 text-sm">
                    {errors.referralCode}
                  </div>
                ) : null}
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating..." : "Create"}
                </button>
              </Form>
            )}
          </Formik>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Your Referral Codes:</h3>
            {referralCodes.length === 0 ? (
              <p>No referrals found.</p>
            ) : (
              <ul className="list-disc pl-5">
                {referralCodes.map((code, index) => (
                  <li key={index}>{code.code}</li>
                ))}
              </ul>
            )}
          </div>

          {/* The "Apply Referral Code" section has been removed */}
        </>
      );
    } else if (tab === "users") {
      return (
        <div>
          <p>Total Referrals: {totalReferrals}</p>
          <p>Total Commission Earned: ${totalCommission.toFixed(2)}</p>
          <br />
          <p>Referred Users:</p>
          {referredUsers.length === 0 ? (
            <p>No referred users yet.</p>
          ) : (
            <ul>
              {referredUsers.map((user, index) => (
                <li key={index}>{user}</li>
              ))}
            </ul>
          )}
          <br />
          <p>Your referral link:</p>
          {referralCodes.map((code, index) => (
            <a
              href={`http://${process.env.REACT_APP_API_URL}:3000/register?ref=${code.code}`}
              key={index}
            >
              {`http://${process.env.REACT_APP_API_URL}:3000/register?ref=${code.code}`}
            </a>
          ))}
        </div>
      );
    } else {
      return <div>Invalid tab selected</div>;
    }
  };

  console.log("User object:", user);

  return (
    <StyleProfile>
      <AccountPageTitle icon={FLOW} title="Referrals" />

      <ReferralBanner
        totalReferrals={totalReferrals}
        totalCommission={totalCommission}
      />

      <AccountTabs tabs={tabs} />

      {renderTabContent()}

      {/* DIVIDER */}
      <div className="section-divider">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              width: "100%",
              color: "#B1B6C6",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16.8px",
            }}
          >
            If you're a content creator, make sure to check out our{" "}
            <span
              style={{
                color: "#FFB018",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16.8px",
              }}
            >
              Partnership Program
            </span>
            .
          </p>
        </div>
      </div>
    </StyleProfile>
  );
};

export default Referrals;
