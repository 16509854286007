import React, { useEffect, useState } from "react";
import COIN from "../../../assets/modelImages/IMAGE (1).png";
import BUCKET1 from "../../../assets/modelImages/IMAGE (2).png";
import BUCKET2 from "../../../assets/modelImages/IMAGE (3).png";
import BUCKET3 from "../../../assets/modelImages/IMAGE (4).png";
import BUCKET4 from "../../../assets/modelImages/IMAGE (5).png";
import REBOTE from "../../../assets/modelImages/IMAGE (6).png";
import CARD from "../../../assets/modelImages/IMAGE (7).png";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const options = [
  {
    icon: COIN,
    name: "CALENDAR",
    buttonType: "ADD",
  },
  {
    icon: BUCKET1,
    name: "DAILY BONUS",
    buttonType: "CLAIM",
    bonusType: "daily",
  },
  {
    icon: BUCKET2,
    name: "WEEKLY BONUS",
    buttonType: "CLAIM",
    bonusType: "weekly",
  },
  {
    icon: BUCKET3,
    name: "MONTHLY BONUS",
    buttonType: "CLAIM",
    bonusType: "monthly",
  },
  {
    icon: BUCKET4,
    name: "RAKEBACK",
    buttonType: "ADD",
  },
  {
    icon: REBOTE,
    name: "ROLLBACK",
    buttonType: "ADD",
  },
  {
    icon: CARD,
    name: "FREE SIDEBET",
    buttonType: "ADD",
  },
];

const Model1 = () => {
  const user = useAuthUser();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
          );
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  const calculateBonus = (bonusType) => {
    if (!userData || !userData.wager) return 0;
    const wager = userData.wager;
    switch (bonusType) {
      case "daily":
        return (wager * 0.01 * 0.07).toFixed(4);
      case "weekly":
        return (wager * 0.01 * 0.05).toFixed(4);
      case "monthly":
        return (wager * 0.01 * 0.03).toFixed(4);
      default:
        return 0;
    }
  };

  const canClaimBonus = (bonusType) => {
    if (!userData || !userData.league) return false;
    const rank = userData.league.toLowerCase();
    const currentTime = new Date().getTime();

    const timeDifference = (lastClaimTime) => {
      if (!lastClaimTime) return currentTime;
      return (
        (currentTime - new Date(lastClaimTime).getTime()) / (1000 * 60 * 60)
      ); // difference in hours
    };

    switch (bonusType) {
      case "daily":
        return (
          (rank.startsWith("bronze") ||
            rank.startsWith("silver") ||
            rank.startsWith("gold") ||
            rank.startsWith("platinum")) &&
          timeDifference(userData.dailyBonus) >= 24
        );
      case "weekly":
        return (
          (rank.startsWith("gold") || rank.startsWith("platinum")) &&
          timeDifference(userData.weeklyBonus) >= 24 * 7
        );
      case "monthly":
        return (
          rank.startsWith("platinum") &&
          timeDifference(userData.monthlyBonus) >= 24 * 30
        );
      default:
        return false;
    }
  };

  const handleClaimBonus = async (option) => {
    if (!user || !userData) return;

    try {
      const response = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/claim-bonus`,
        {
          username: user.username,
          bonusType: option.bonusType,
        }
      );

      if (response.data.success) {
        // Update the local userData state
        setUserData((prevData) => ({
          ...prevData,
          [option.bonusType + "Bonus"]: new Date().toISOString(),
          balance:
            prevData.balance + parseFloat(calculateBonus(option.bonusType)),
        }));

        // Show a success message to the user
        alert(`Successfully claimed ${option.name}!`);
      } else {
        // Show an error message
        alert(
          response.data.message || "Failed to claim bonus. Please try again."
        );
      }
    } catch (error) {
      console.error("Error claiming bonus:", error);
      alert("An error occurred while claiming the bonus. Please try again.");
    }
  };

  return (
    <>
      {options.map((option, index) => (
        <div
          key={index}
          style={{
            padding: "12px",
            marginBottom: "8px",
            display: "flex ",
            justifyContent: "space-between",
          }}
        >
          <div className="flex items-center">
            <img
              src={option.icon}
              alt="icon"
              style={{ width: "44px", height: "26px" }}
            />

            <div
              style={{ padding: "-10px 0", margin: "-8px 13px", color: "#fff" }}
            >
              <p>{option.name}</p>
              {option.buttonType === "CLAIM" &&
                canClaimBonus(option.bonusType) && (
                  <p
                    style={{
                      color: "#72F238",
                      fontSize: "14px",
                      display: "flex",
                      gap: "7px",
                    }}
                  >
                    Pending: <span>${calculateBonus(option.bonusType)}</span>
                  </p>
                )}
            </div>
          </div>

          <button
            style={{
              display: "flex",
              padding: "10px 14px",
              alignItems: "flex-start",
              gap: "10px",
              borderRadius: "10px",
              background: option.buttonType === "CLAIM" ? "#86F454" : "#FFE81A",
              boxShadow:
                option.buttonType === "CLAIM"
                  ? "0px 0px 10px 0px rgba(118, 255, 25, 0.40)"
                  : "0px 0px 10px 0px rgba(255, 176, 25, 0.40)",
              opacity:
                option.buttonType === "CLAIM" &&
                !canClaimBonus(option.bonusType)
                  ? 0.5
                  : 1,
              cursor:
                option.buttonType === "CLAIM" &&
                !canClaimBonus(option.bonusType)
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={
              option.buttonType === "CLAIM" && !canClaimBonus(option.bonusType)
            }
            onClick={() => handleClaimBonus(option)}
          >
            {option.buttonType}
          </button>
        </div>
      ))}
    </>
  );
};

export default Model1;
