import React, { useContext, useState, useCallback, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import { DATA_CONTENT } from "../../../assets/MockData/mockData";
import { ReactComponent as SEARCH_ICON } from "../../../assets/images/navbar-search-icon.svg";
import Button from "../../Common/Buttons/Button";
import ImagesSection from "../../Common/ImagesSection/ImagesSection";
import SearchAndFilters from "../../Common/SearchAndFilters/SearchAndFilters";
import CloseButtonModal from "../CloseButtonModal";
import Modal from "../Modal";
import ModalSidebar from "./ModalSidebar";
import { StyledSearchContent } from "./styles";
import axios from "axios";

const SearchModal = () => {
  const { isTabletScreen, searchState } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Casino");
  const [selectedSubcategory, setSelectedSubcategory] =
    useState("AK_ORIGINALS");
  const [games, setGames] = useState([]);
  const [once, setOnce] = useState(false);

  useEffect(() => {
    setOnce(false);
  }, [searchState]);

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        searchState.searchQuery.length > 0
          ? `http://${process.env.REACT_APP_API_URL}:5005/search?query=${searchState.searchQuery}`
          : `http://${process.env.REACT_APP_API_URL}:5005/filter?sort=plays&order=-1`
      );
      // console.log(res.data);
      if (res.data) {
        res.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) games.push(game);
        });
        setGames([]);
        setGames(res.data);
        console.log(games);
      }
    };
    if (!once) {
      run();
      setOnce(true);
    }
  }, [once, searchState.searchQuery, games]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleCategoryChange = useCallback((newCategory) => {
    setSelectedCategory(newCategory);
  }, []);

  const handleSubcategoryChange = useCallback((newSubcategory) => {
    setSelectedSubcategory(newSubcategory);
  }, []);

  return (
    <>
      <Button
        style={{ marginLeft: "1rem", marginRight: "1rem" }}
        onClick={() => setIsOpen(true)}
      >
        <SEARCH_ICON />
      </Button>

      <Modal
        maxWidth={1408}
        maxHeight={"90vh"}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <StyledSearchContent
          url={
            "https://rollbit.com/static/media/hero-rlb-bot.80736408d58b43236799.jpg"
          }
        >
          <CloseButtonModal onClick={handleClose} />
          <div className="content-container">
            <div className="div-container">
              {!isTabletScreen && (
                <ModalSidebar
                  selectedCategory={selectedCategory}
                  onCategoryChange={handleCategoryChange}
                />
              )}
              <div className="game-container h-[30rem]">
                <h1 className="title">Select Game</h1>
                <SearchAndFilters
                  hasSortByOptions={true}
                  hasProviderOptions={true}
                />
                <ImagesSection
                  category={selectedCategory}
                  subcategory={selectedSubcategory}
                  images={games}
                />
              </div>
            </div>
          </div>
        </StyledSearchContent>
      </Modal>
    </>
  );
};

export default SearchModal;

// import React, { useContext, useState, useCallback, useEffect } from "react";
// import { AppContext } from "../../../AppContext";
// import { DATA_CONTENT } from "../../../assets/MockData/mockData";
// import { ReactComponent as SEARCH_ICON } from "../../../assets/images/navbar-search-icon.svg";
// import Button from "../../Common/Buttons/Button";
// import ImagesSection from "../../Common/ImagesSection/ImagesSection";
// import SearchAndFilters from "../../Common/SearchAndFilters/SearchAndFilters";
// import CloseButtonModal from "../CloseButtonModal";
// import Modal from "../Modal";
// import ModalSidebar from "./ModalSidebar";
// import { StyledSearchContent } from "./styles";
// import axios from "axios";

// const SearchModal = () => {
//   const { isTabletScreen, searchState } = useContext(AppContext);

//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState("Casino");
//   const [selectedSubcategory, setSelectedSubcategory] =
//     useState("AK_ORIGINALS");
//   const [games, setGames] = useState([]);
//   const [once, setOnce] = useState(false);

//   useEffect(() => {
//     setOnce(false);
//   }, [searchState]);

//   useEffect(() => {
//     const run = async () => {
//       const res = await axios.get(
//         searchState.searchQuery.length > 0
//           ? `http://${process.env.REACT_APP_API_URL}:5005/search?query=${searchState.searchQuery}`
//           : `http://${process.env.REACT_APP_API_URL}:5005/getGamesList?type=fast-games&skip=0&limit=20`
//       );
//       // console.log(res.data);
//       if (res.data) {
//         res.data.forEach((game) => {
//           if (!game.id_hash.includes("mobile")) games.push(game);
//         });
//         setGames(res.data);
//         console.log(games);
//       }
//     };
//     if (!once) {
//       run();
//       setOnce(true);
//     }
//   });

//   const handleClose = useCallback(() => {
//     setIsOpen(false);
//   }, []);

//   const handleCategoryChange = useCallback((newCategory) => {
//     setSelectedCategory(newCategory);
//   }, []);

//   const handleSubcategoryChange = useCallback((newSubcategory) => {
//     setSelectedSubcategory(newSubcategory);
//   }, []);

//   return (
//     <>
//       <Button
//         style={{ marginLeft: "1rem", marginRight: "1rem" }}
//         onClick={() => setIsOpen(true)}
//       >
//         <SEARCH_ICON />
//       </Button>

//       <Modal
//         maxWidth={1408}
//         maxHeight={"90vh"}
//         isOpen={isOpen}
//         onClose={handleClose}
//       >
//         <StyledSearchContent
//         // url={
//         //   "https://rollbit.com/static/media/hero-rlb-bot.80736408d58b43236799.jpg"
//         // }
//         >
//           <CloseButtonModal onClick={handleClose} />
//           <div className="content-container">
//             <div className="div-container">
//               {!isTabletScreen && (
//                 <ModalSidebar
//                   selectedCategory={selectedCategory}
//                   onCategoryChange={handleCategoryChange}
//                 />
//               )}
//               <div className="game-container">
//                 <h1 className="title">Select Game</h1>

//                 <SearchAndFilters
//                   hasSortByOptions={true}
//                   hasProviderOptions={true}
//                 />

//                 <ImagesSection
//                   category={selectedCategory}
//                   subcategory={selectedSubcategory}
//                   images={games}
//                 />
//               </div>
//             </div>
//           </div>
//         </StyledSearchContent>
//       </Modal>
//     </>
//   );
// };

// export default SearchModal;
