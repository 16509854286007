import React, { useEffect, useState, useCallback } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { StyledUserContainer } from "./styles";
import axios from "axios";

const ranks = [
  { name: "Unranked", wager: 0, bonus: 0.0 },
  { name: "Bronze 1", wager: 10000, bonus: 10.0 },
  { name: "Bronze 2", wager: 20000, bonus: 19.0 },
  { name: "Bronze 3", wager: 30000, bonus: 27.0 },
  { name: "Silver 1", wager: 50000, bonus: 42.5 },
  { name: "Silver 2", wager: 75000, bonus: 60.0 },
  { name: "Silver 3", wager: 100000, bonus: 75.0 },
  { name: "Gold 1", wager: 200000, bonus: 140.0 },
  { name: "Gold 2", wager: 300000, bonus: 195.0 },
  { name: "Gold 3", wager: 400000, bonus: 240.0 },
  { name: "Platinum 1", wager: 800000, bonus: 440.0 },
  { name: "Platinum 2", wager: 1000000, bonus: 500.0 },
  { name: "Platinum 3", wager: 2000000, bonus: 900.0 },
  { name: "Diamond 1", wager: 10000000, bonus: 4000.0 },
  { name: "Diamond 2", wager: 50000000, bonus: 17500.0 },
  { name: "Diamond 3", wager: 100000000, bonus: 30000.0 },
  { name: "Blood Diamond", wager: 500000000, bonus: 125000.0 },
  { name: "Vibranium", wager: 1000000000, bonus: 200000.0 },
];

const rankNames = ranks.map((e) => e.name);

const UserContainer = () => {
  const user = useAuthUser();
  const [wager, setWager] = useState(0);
  const [currentRank, setCurrentRank] = useState(ranks[0]);
  const [nextRank, setNextRank] = useState(ranks[1]);
  const [progress, setProgress] = useState(0);
  const [prevLeague, setPrevLeague] = useState(null);
  const [lastLevelUpLeague, setLastLevelUpLeague] = useState(null);
  const [levelUpBonus, setLevelUpBonus] = useState(0);

  const fetchWager = useCallback(async () => {
    if (!user?.username) {
      console.error("No username available");
      return;
    }
    try {
      console.log("Fetching wager for user:", user.username);
      const response = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
      );
      console.log("Fetched data:", response.data);
      setWager(parseFloat(response.data.wager));
      setPrevLeague(response.data.league);
      setLastLevelUpLeague(response.data.lastLevelUpLeague);
    } catch (error) {
      console.error("Error fetching wager:", error);
    }
  }, [user]);

  useEffect(() => {
    console.log("First useEffect triggered");
    if (user) {
      fetchWager();
    } else {
      console.log("No user");
    }
  }, [user, fetchWager]);

  useEffect(() => {
    console.log("Second useEffect triggered, wager:", wager);
    if (wager > 0) {
      const currentRankObj =
        ranks
          .slice()
          .reverse()
          .find((rank) => rank.wager <= wager) || ranks[0];
      const nextRankObj =
        ranks.find((rank) => rank.wager > wager) || currentRankObj;

      const progressPercentage =
        nextRankObj.wager > currentRankObj.wager
          ? Math.min(
              ((wager - currentRankObj.wager) /
                (nextRankObj.wager - currentRankObj.wager)) *
                100,
              100
            )
          : 100;

      setCurrentRank(currentRankObj);
      setNextRank(nextRankObj);
      setProgress(progressPercentage);
      console.log("Rank updated:", {
        currentRank: currentRankObj.name,
        nextRank: nextRankObj.name,
        progress: progressPercentage,
      });
      (async () => {
        if (prevLeague !== currentRankObj.name) {
          const response = await axios.post(
            `http://${process.env.REACT_APP_API_URL}:5005/updateUser`,
            {
              username: user.username,
              query: { $set: { league: currentRankObj.name } },
            }
          );
          if (response.status !== 200) {
            console.error("Error updating user:", response.data);
          }
        }
        if (
          rankNames.indexOf(lastLevelUpLeague) <
          rankNames.indexOf(currentRankObj.name)
        ) {
          console.log(
            "Next claim",
            ranks[rankNames.indexOf(lastLevelUpLeague) + 1]
          );
          setLevelUpBonus(
            ranks[rankNames.indexOf(lastLevelUpLeague) + 1].bonus
          );
        }
      })();
    }
  }, [wager, prevLeague, user, lastLevelUpLeague]);

  if (!user) {
    return <div>No user found</div>;
  }

  console.log("Level Up Bonus:", levelUpBonus);

  const handleClaim = async () => {
    if (
      rankNames.indexOf(lastLevelUpLeague) <
        rankNames.indexOf(currentRank.name) &&
      levelUpBonus > 0
    ) {
      const res1 = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/updateUser`,
        {
          username: user.username,
          query: {
            $set: {
              lastLevelUpLeague:
                ranks[rankNames.indexOf(lastLevelUpLeague) + 1].name,
            },
          },
        }
      );
      const res2 = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/updateUser`,
        {
          username: user.username,
          query: { $inc: { "balance.gmf": levelUpBonus } },
        }
      );
      if (res1.status !== 200 || res2.status !== 200) {
        console.error("Error updating user:", res1.data, res2.data);
      } else {
        setLastLevelUpLeague(
          ranks[rankNames.indexOf(lastLevelUpLeague) + 1].name
        );
        setLevelUpBonus(0);
        alert("Claimed " + levelUpBonus);
      }
    }
  };

  return (
    <div className="">
      <StyledUserContainer>
        <div className="rank-logo">
          <img
            src="https://s3-alpha.figma.com/hub/file/3528446083/451a569b-97c5-436d-8c1b-caa998bba178-cover.png"
            alt={currentRank.name}
            style={{ transform: "scale(0.96)" }}
          />
        </div>
        <div className="user-info-container">
          <div className="user-name">{user.username}</div>
          <div className="progress-bar">
            <div
              className="progress-bar-filled"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="level-container">
            <div className="level-section">
              <span className="rank-info">{currentRank.name}</span>
            </div>
            <div className="level-section">
              <span className="rank-info">Next:</span>
              <div className="rank-small-logo">
                <img
                  src="https://s3-alpha.figma.com/hub/file/3528446083/451a569b-97c5-436d-8c1b-caa998bba178-cover.png"
                  alt={nextRank.name}
                  style={{ transform: "scale(0.72)" }}
                />
              </div>
              <div className="rank-title">{nextRank.name}</div>
              <span className="rank-info">
                ${nextRank.wager.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </StyledUserContainer>
      {levelUpBonus != 0 && (
        <div className="flex flex-row items-start justify-center gap-4">
          <p>Level Up Bonus</p>
          <button
            className="bg-yellow-500 text-black py-1 px-2 rounded-md"
            onClick={handleClaim}
          >
            Claim
          </button>
        </div>
      )}
    </div>
  );
};

export default UserContainer;
