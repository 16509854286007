import React from "react";

//components
import MainHome from "../MainHome/MainHome";

//assets

const Home = () => {
  return <MainHome />;
};

export default Home;
