import React, { useContext, useEffect } from "react";
import { StyledSocialMediaButton } from "./StyledSocialMediaButton";
import axios from "axios";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { AppContext } from "../../../../AppContext";
import { useGoogleLogin } from "@react-oauth/google";

const SocialMediaButton = ({ socialIcon: Icon, socialName, url, auth }) => {
  const signIn = useSignIn();
  const { updateLoggedIn } = useContext(AppContext);

  useEffect(() => {
    // Load Google API script
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = async () => {
    if (socialName === "Google") {
      googleLogin();
    } else if (socialName === "Metamask") {
      if (typeof window.ethereum !== "undefined") {
        try {
          // Request account access
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          console.log("Metamask connected successfully");

          if (accounts.length > 0) {
            const address = accounts[0];

            // if (auth === "login") {
            //   const response = await axios.get(
            //     `http://${
            //       process.env.REACT_APP_API_URL
            //     }:5005/user/user_${address.slice(-8)}`
            //   );
            //   if (response.data) {
            //     console.log(response.data);
            //     if (
            //       signIn({
            //         auth: { token: response.data.token, type: "Bearer" },
            //         userState: {
            //           username: response.data.username,
            //           id: response.data.userId,
            //           method: "metamask",
            //           address: address,
            //         },
            //       })
            //     ) {
            //       console.log("User signed in successfully");
            //       updateLoggedIn(true);
            //     }
            //   }
            //   return;
            // }

            // Register user
            const response = await axios.post(
              `http://${process.env.REACT_APP_API_URL}:5005/register-metamask`,
              {
                address,
                provider: "Metamask",
              }
            );

            const data = response.data;
            console.log(data);

            if (data.success) {
              console.log("User registered successfully");

              if (
                signIn({
                  auth: { token: data.token, type: "Bearer" },
                  userState: {
                    username: data.user.username,
                    id: data.user.userId,
                    method: "metamask",
                    address: address,
                  },
                })
              ) {
                console.log("User signed in successfully");
                updateLoggedIn(true);
              }
            } else {
              console.error("Failed to register user:", data.message);
            }
          }
        } catch (error) {
          console.error(
            "Failed to connect to Metamask or register user:",
            error
          );
        }
      } else {
        console.error("Metamask is not installed");
        window.open("https://metamask.io/download.html", "_blank");
      }
    } else {
      window.open(url, "_blank");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      console.log("Google sign-in successful", response);
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v2/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        }
      );
      console.log(res.data);
      const { email } = res.data;
      const backendResponse = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/register-google`,
        { email }
      );
      if (backendResponse.data.success) {
        console.log(backendResponse.data);
        console.log("User registered successfully");
        if (
          signIn({
            auth: { token: backendResponse.data.token, type: "Bearer" },
            userState: {
              username: backendResponse.data.user.username,
              id: backendResponse.data.user.userId,
              method: "google",
              email: backendResponse.data.user.email,
            },
          })
        ) {
          console.log("User signed in successfully with Google");
          updateLoggedIn(true);
        }
      }
    },
    onError: (error) => console.log("Google sign-in error", error),
  });

  const handleGoogleSignIn = async (response) => {
    try {
      const { credential } = response;
      const backendResponse = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/auth/google`,
        { token: credential }
      );

      const { success, token, user } = backendResponse.data;

      if (success) {
        if (
          signIn({
            auth: { token, type: "Bearer" },
            userState: {
              username: user.username,
              id: user.userId,
              method: "google",
              email: user.email,
            },
          })
        ) {
          console.log("User signed in successfully with Google");
          updateLoggedIn(true);
        }
      } else {
        console.error("Failed to authenticate with Google");
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    }
  };

  return (
    <StyledSocialMediaButton onClick={handleClick}>
      <Icon className="icon-img" style={{ width: "17px", height: "17px" }} />
      <p className="btn-text">{socialName}</p>
    </StyledSocialMediaButton>
  );
};

export default SocialMediaButton;
