import React, { useEffect, useState } from "react";
import DOT from "../../../assets/images/Rectangle.png";
import socketIO from "socket.io-client";
import { WINNER_CARDS } from "../../../assets/MockData/mockData";
import FilterButtonGroup from "../Buttons/FilterButtonGroup";
import WinnerCard from "./WinnerCard";
import { LiveWinsSectionStyled, StyledCardsContainer } from "./styles";

const LiveWinsSection = ({ icon: Icon, title, hasFilters }) => {
  const timeFilterOptions = ["Live", "Month", "Week", "Day"];

  const socket = socketIO(`http://${process.env.REACT_APP_API_URL}:3001`);
  socket.connect();

  // setInterval(() => {
  //   socket.emit("newWin", {
  //     game: "Mines",
  //     username: "JaneSmith",
  //     payout: 132.4,
  //   });
  // }, 10000);

  const [activeOption, setActiveOption] = useState(timeFilterOptions[0]);
  const [wins, setWins] = useState([]);

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  useEffect(() => {
    socket.on("newWin", (newWin) => {
      console.log("new live win:", newWin);
      const winQueue = [...wins, newWin];
      if (winQueue.length > 8) winQueue.shift();
      setWins(winQueue);
      console.log(winQueue, wins);
    });
    return () => socket.disconnect();
  }, []);

  const selectedCards = WINNER_CARDS[activeOption] || [];

  return (
    <>
      <LiveWinsSectionStyled>
        <div className="dot-section">
          {hasFilters ? (
            <img src={DOT} alt="dot" className="dot-icon" />
          ) : (
            <Icon className="wins-icon" />
          )}
          {/* {icon && <img src={DOT} alt="dot" className="dot-icon" />} */}
          {title && <span className="live-wins-text uppercase">{title}</span>}
        </div>

        {hasFilters && (
          <FilterButtonGroup
            options={timeFilterOptions}
            onOptionChange={handleOptionChange} // Pass the callback for option change
          />
        )}
      </LiveWinsSectionStyled>

      {/* <StyledCardsContainer> */}
      {wins.map((card, index) => (
        <div className="bg-[#20232f] w-max h-auto flex flex-col rounded-lg p-2">
          <h1 className="text-white text-xl">{card.game}</h1>
          <h1 className="text-white text-lg">{card.username}</h1>
          <h1 className="text-green-500 text-base">{card.payout}</h1>
        </div>
      ))}
      {/* </StyledCardsContainer> */}
    </>
  );
};

{
  /* <WinnerCard
            key={index}
            imgSrc={card.imgSrc}
            cartIconSrc={card.cartIconSrc}
            username={card.username}
            price={card.price}
          /> */
}

export default LiveWinsSection;
