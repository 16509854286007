import React, { useContext, useState } from "react";
// import { SignOutButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { ACCOUNT_DROPDOWN_OPTIONS } from "../../../assets/MockData/mockData";
import { ReactComponent as OUT } from "../../../assets/modelImages/Frame (12).svg";
import { StyledAccountDropdown } from "./styles";
import useSignOut from "react-auth-kit/hooks/useSignOut";
const AccountDropdown = ({ userName }) => {
  const { updateLoggedIn, toggleDropdown } = useContext(AppContext);
  const [dropdownOptions, setDropdownOptions] = useState(
    ACCOUNT_DROPDOWN_OPTIONS
  );

  const signOut = useSignOut();

  return (
    <StyledAccountDropdown>
      <p className="hello-text">Hello, {userName}</p>

      {ACCOUNT_DROPDOWN_OPTIONS.map((option, index) => (
        <Link
          className="dropdown-option link"
          to={option.route}
          key={index}
          onClick={() => toggleDropdown("")}
        >
          <option.icon />
          <span>{option.label}</span>
        </Link>
      ))}

      <Link
        className="dropdown-option link"
        to={"/"}
        onClick={() => {
          updateLoggedIn(false);
          toggleDropdown("");
        }}
      >
        {/* <OUT /> */}
        <button
          onClick={() => {
            signOut();
            localStorage.clear();
          }}
        >
          Log Out
        </button>
      </Link>
    </StyledAccountDropdown>
  );
};

export default AccountDropdown;
