import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import { AppProvider } from "./AppContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { AuthProvider } from "react-auth-kit";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import { GoogleOAuthProvider } from "@react-oauth/google";

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: process.env.REACT_APP_API_URL,
  cookieSecure: window.location.protocol === "https:",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider store={store}>
    <GoogleOAuthProvider clientId="347866723934-v7v70dqjs1uks2ia99eb72c2677dqj75.apps.googleusercontent.com">
      <AppProvider>
        <App />
      </AppProvider>
    </GoogleOAuthProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
