import React, { useState, useEffect } from "react";
import { StyledCouponsContent } from "./styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const CouponsReferralsContent = () => {
  const user = useAuthUser();
  const [toastMessage, setToastMessage] = useState("");
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    if (user) {
      const registrationTime = new Date(user.createdAt).getTime();
      const currentTime = new Date().getTime();
      const hoursSinceRegistration =
        (currentTime - registrationTime) / (1000 * 60 * 60);
      console.log(currentTime, user.createdAt);
      setIsEligible(hoursSinceRegistration <= 24);
    }
  }, [user]);

  const codeValidationSchema = Yup.object().shape({
    code: Yup.string().required("Referral code is required"),
  });

  const displayToast = (message) => {
    setToastMessage(message);
    setTimeout(() => {
      setToastMessage("");
    }, 3000);
  };

  const handleSubmit = async (values, { resetForm }) => {
    // if (!isEligible) {
    //   displayToast("You are not eligible to apply a referral code yet.");
    //   return;
    // }

    try {
      const response = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/set-referrer`,
        {
          referralCode: values.code,
          userId: user?.id,
          username: user?.username,
        }
      );

      if (response.success) {
        displayToast("Referral code applied successfully");
      } else {
        alert(response.data.error || "Failed to apply referral code.");
      }
    } catch (error) {
      console.error("Error applying referral code:", error.message);
      alert(error.response?.data?.error || "Failed to apply referral code.");
    }
    resetForm();
  };

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeValidationSchema,
    onSubmit: handleSubmit,
  });

  // console.log("Is eligible", isEligible);

  return (
    <div className="">
      <form onSubmit={codeFormik.handleSubmit}>
        <StyledCouponsContent>
          <img
            src="https://rollbit.com/static/media/coin.c832c5bf7167a98755e1.webp"
            size="153"
            className="css-1ges8if"
            style={{ margin: "24px 0px 32px" }}
            alt=""
          />
          <h1 className="title">Apply referral code</h1>
          <div className="input-container">
            <input
              type="text"
              name="code"
              placeholder="Gamify."
              value={codeFormik.values.code}
              onChange={codeFormik.handleChange}
              // disabled={!isEligible}
            />
          </div>
          <div className="info-text">
            Don't have a code? Enter <a href="">"Gamify"</a>
          </div>
          <button
            className="claim-button"
            type="submit"
            style={{ width: "140px", marginTop: "40px" }}
            // disabled={!isEligible}
          >
            Apply
          </button>
          {/* {!isEligible && (
            <div
              className="info-text"
              style={{ marginTop: "10px", color: "red" }}
            >
              Referral codes can only be applied after 24 hours of registration.
            </div>
          )} */}
        </StyledCouponsContent>
      </form>
      {toastMessage && <div className="toast">{toastMessage}</div>}
    </div>
  );
};

export default CouponsReferralsContent;
