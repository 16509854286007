import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const GatesOfOlympus = () => {
  const user = useAuthUser();
  const [link, setLink] = useState("");

  (async () => {
    const res = await axios.post(
      `http://${process.env.REACT_APP_API_URL}:5005/getGame`,
      {
        username: user.username,
        password: "Ben1441#",
        gameId: "129687",
      }
    );
    setLink(res.data.response);
  })();
  return (
    <div className="flex flex-col items-center justify-center gap-10">
      <h1
        className="text-4xl font-bold text-white"
        // style={{
        //   color: "#fff",
        // }}
      >
        Gates of Olympus
      </h1>
      <iframe
        className="rounded-xl"
        src={link}
        width={1000}
        height={600}
        frameborder="0"
        title="Gates of Olympus"
      ></iframe>
    </div>
  );
};

export default GatesOfOlympus;
