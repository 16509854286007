import { useContext, useState, useEffect } from "react";
import socketIO from "socket.io-client";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { ReactComponent as MSG } from "../../../assets/images/Frame (31).svg";
import { ReactComponent as COLLAPSE } from "../../../assets/images/Frame (34).svg";
import { ReactComponent as CROSS } from "../../../assets/images/Frame (35).svg";
import { ReactComponent as SETTINGS } from "../../../assets/images/Frame (36).svg";
import EMOJI from "../../../assets/images/IMAGE (43).png";
import RANK_ICON from "../../../assets/images/rank-icon-gold.png";
import CryptoFuturesCoins from "../../Common/CryptoFuturesCoins/CryptoFuturesCoins";
import CardMessage from "./CardMessage";
import {
  ImagePart,
  MessageInput,
  StyledChatBoxContainer,
  StyledIconSection,
  SwitchContainer,
} from "./styles";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const ChatBox = ({ isChatBox, setIsChatBox }) => {
  const user = useAuthUser();

  const socket = socketIO(`http://${process.env.REACT_APP_API_URL}:3001`);
  socket.connect();

  const { pathname } = useLocation();
  const { isTabletScreen, isChatBoxCollapsed, updateChatBoxCollapsed } =
    useContext(AppContext);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([{}]);
  const [activeButton, setActiveButton] = useState("chat");
  const [characterCount, setCharacterCount] = useState(200);
  const [isSending, setIsSending] = useState(false);
  const [timer, setTimer] = useState(10);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  useEffect(() => {
    socket.on("message", (chat) => {
      setMessages([...messages, { user: chat.user, message: chat.message }]);
      console.log(message, chat.message);
    });
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    let countdown;
    if (isSending) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            setIsSending(false);
            clearInterval(countdown);
            return 10;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isSending]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleSend = () => {
    if (message) {
      // handleSocketClick(message);
      // socket.connect();
      socket.emit("message", { message, id: socket.id, user: user.username });
      setMessages([...messages, { user: user.username, message }]);
      setMessage("");
      setCharacterCount(200);
      setIsSending(true);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 200) {
      setMessage(value);
      setCharacterCount(200 - value.length);
    }
  };

  const handleCollapse = () => {
    updateChatBoxCollapsed(!isChatBoxCollapsed);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && !isSending) {
      handleSend();
    }
  };

  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  return (
    <>
      {isChatBox ? (
        <StyledChatBoxContainer
          isTabletScreen={isTabletScreen}
          style={{
            padding: pathname.includes("/sports")
              ? "0px 16px 64px"
              : "0px 16px 16px",
            width: isChatBoxCollapsed ? "248px" : "340px",
          }}
        >
          <div className="top-actions-container">
            <div className="chat-trades">
              <div
                className={`btn-chatbox ${
                  activeButton === "chat" ? "active-btn" : ""
                }`}
                onClick={() => handleButtonClick("chat")}
              >
                <MSG />
                Chat
              </div>
            </div>

            <SwitchContainer>
              <div className="container-buttons">
                <COLLAPSE
                  className={`collapse-icon ${
                    isChatBoxCollapsed ? "collapsed" : ""
                  }`}
                  onClick={handleCollapse}
                />
                <CROSS
                  className="cross-icon"
                  onClick={() => setIsChatBox(false)}
                />
              </div>
            </SwitchContainer>
          </div>

          {activeButton === "chat" ? (
            <>
              {messages.length === 0 ? (
                <ImagePart>
                  <p>No Messages Yet</p>
                </ImagePart>
              ) : (
                <div className="chat-messages">
                  {messages.map((msg, index) => (
                    <CardMessage
                      key={index}
                      rankIcon={RANK_ICON}
                      playerName={msg.user}
                      messageText={msg.message}
                    />
                  ))}
                </div>
              )}

              <MessageInput
                type="text"
                placeholder="Type a message..."
                value={message}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                disabled={isSending}
              />

              <StyledIconSection>
                <div className="icons">
                  <img src={EMOJI} alt="emoji" className="emoji" />
                  <SETTINGS
                    className="settings"
                    onClick={toggleNotifications}
                  />
                  <p>Rules</p>
                </div>

                <div className="info">
                  <p className="info-value">{characterCount}</p>
                  <button
                    onClick={handleSend}
                    className="send-button"
                    disabled={!message || isSending}
                  >
                    {isSending ? `${timer}s` : "Send"}
                  </button>
                </div>
              </StyledIconSection>

              <div className="notification-toggle">
                <span>
                  Notifications: {notificationsEnabled ? "Enabled" : "Disabled"}
                </span>
              </div>
            </>
          ) : (
            <div className="container-bets">
              <div className="section-title" style={{ marginBottom: "21px" }}>
                My Active Bets
              </div>
              <div className="active-bets" style={{ padding: "0px 0px 10px" }}>
                <div className="text">No Bets Yet</div>
              </div>
              <div style={{ paddingTop: "25px" }}>
                <div className="section-title" style={{ marginBottom: "10px" }}>
                  Market Prices
                </div>
                <CryptoFuturesCoins />
              </div>
            </div>
          )}
        </StyledChatBoxContainer>
      ) : null}
    </>
  );
};

export default ChatBox;
