import React from "react";
import { StyleReferralBanner } from "./styles";

const ReferralBanner = ({ totalReferrals, totalCommission }) => {
  return (
    <StyleReferralBanner>
      <div className="banner-title">Refer Friends And Get Rewarded</div>
      <div className="content-section">
        <div>
          <div className="text">Total Referred Users</div>
          <div className="value">{totalReferrals}</div>
        </div>
        <div>
          <div className="text">Total Commission</div>
          <div className="value" style={{ color: "rgb(114, 242, 56)" }}>
            ${totalCommission.toFixed(2)}
          </div>
        </div>
      </div>
      <button className="button" disabled={totalCommission <= 0} style={{ minWidth: "240px" }}>
        {totalCommission > 0 ? `Claim $${totalCommission.toFixed(2)}` : "Nothing to Claim"}
      </button>
    </StyleReferralBanner>
  );
};

export default ReferralBanner;
