import React from "react";
import HoverableImage from "../HoverableImage/HoverableImage";
import NotFound from "../NotFound/NotFound";
import { StyledImageSection } from "./styles";

const ImagesSection = ({ category, subcategory, images }) => {
  return (
    <StyledImageSection>
      <div className="image-cards">
        {Array.isArray(images) ? (
          images.map((image, index) => (
            <HoverableImage
              key={index}
              src={image.image_portrait}
              alt="casino"
              link={"/casino/games/" + image.id_hash}
            />
          ))
        ) : (
          <p>No images available</p>
        )}
      </div>
    </StyledImageSection>
  );
};

export default ImagesSection;
