import React, { useState, useContext, useEffect, useRef } from "react";
import { Await, Link } from "react-router-dom";
import LOGO from "../../../../constants/logo";
// import FULL_LOGO from "../../../../assets/LOGO Gamblify/Full LOGO.png";
// import SMALL_LOGO_NO_BG from "../../../../assets/LOGO Gamblify/LOGO PNG.png";
import { ReactComponent as MESSAGE_ICON } from "../../../../assets/images/message.svg";
import ChatBox from "../../ChatBox/ChatBox";
import { AppContext } from "../../../../AppContext";
import Button from "../../../Common/Buttons/Button";
import RegisterModal from "../../../Common/Modals/RegisterModal";
import AccountButton from "../AccountButton/AccountButton";
import CashierModal from "../../../Modals/CashierModals/CashierModal";
import SearchModal from "../../../Modals/SearchModal/SearchModal";
import RewardsButton from "../RewardsButton/RewardsButton";
import TotalMoneyContainer from "../TotalMoneyContainer";
import { StyledNavBar } from "./styles";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
// import axios from "axios";

const NavBar = () => {
  const {
    isChatBoxOpen,
    isLoggedIn,
    isMobileScreen,
    isTabletScreen,
    updateChatBox,
    updateLoggedIn,
    balance,
  } = useContext(AppContext);
  console.log("Balance: " + balance);

  const isSignedIn = useIsAuthenticated();
  const authUser = useAuthUser();
  const [user, setUser] = useState(null);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currency, setCurrency] = useState({
    type: balance.selected,
    value: `$ ${balance[balance.selected]}`,
  });

  useEffect(() => {
    if (isLoggedIn) {
      console.log("Setting user data");
      setUser(authUser);
    }
  }, [isLoggedIn, authUser]);

  useEffect(() => {
    if (isSignedIn) {
      // updateLoggedIn(true);
      setCurrency({
        type: balance.selected,
        value: `$ ${balance[balance.selected]}`,
      });
    }
  }, [isSignedIn, balance]);

  const dropdownRef = useRef(null);

  const handleAmountClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleCurrencySelect = async (type) => {
    console.log(user);
    const res = await axios.post(
      `http://${process.env.REACT_APP_API_URL}:5005/updateUser`,
      { username: user.username, query: { $set: { "balance.selected": type } } }
    );
    if (res.status == 200) {
      setCurrency({ type, value: balance[type] });
      setDropdownVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  // USE EFFECT FOR FETCHING BALANCE;

  // useEffect(() => {
  //   let fetchBalance = async () => {
  //     try {
  //       const response = await axios.get(
  //         `http://localhost:5005/user/${user.username}`
  //       );
  //       setCurrency({ type: "USD", value: response.data.balance.usdt });
  //     } catch (error) {
  //       console.log(error);
  //       console.log({ error: "error while fetching balance" });
  //     }
  //   };
  //   if (isLoaded && isSignedIn) {
  //     fetchBalance();
  //   }
  // });

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <StyledNavBar>
      <div
        className="h-16 flex justify-between items-center p-3"
        style={{
          boxShadow: "2px 2px 2px rgba(0,0,0,0.3)",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          background: "#1A1D29",
          zIndex: 11,
        }}
      >
        <div className="logo-container">
          <Link to="/" className="flex h-16 flex-shrink-0 cursor-pointer">
            <img src={LOGO} alt="logo" className="h-16 w-[100px]" />
          </Link>

          {isLoggedIn && (
            <div className="money-container">
              <RewardsButton />
            </div>
          )}
        </div>

        {isLoggedIn && !isMobileScreen && (
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div style={{ position: "relative" }} ref={dropdownRef}>
              <div onClick={handleAmountClick}>
                <TotalMoneyContainer money={currency.value} />
              </div>

              {dropdownVisible && (
                <div
                  className="dropdownVisible"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    background: "rgb(33 79 126)",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    zIndex: 10,
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => handleCurrencySelect("usdt")}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "rgb(255 232 26)")
                    }
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "")}
                  >
                    USD
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => handleCurrencySelect("gmf")}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "rgb(255 232 26)")
                    }
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "")}
                  >
                    GMF
                  </div>
                </div>
              )}
            </div>

            <CashierModal button={"Cashier"} />
          </div>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          {!isLoggedIn && (
            <>
              <RegisterModal modalOption="login" />
              <RegisterModal modalOption="register" />
            </>
          )}

          <div style={{ display: "flex" }}>
            {isLoggedIn && <AccountButton />}
            <SearchModal />

            {isChatBoxOpen === false && (
              <Button className="mr-5" onClick={() => updateChatBox(true)}>
                <MESSAGE_ICON />
              </Button>
            )}
          </div>
        </div>
      </div>

      <ChatBox isChatBox={isChatBoxOpen} setIsChatBox={updateChatBox} />
    </StyledNavBar>
  );
};

export default NavBar;
