import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

const Refer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useAuthUser();
  const isSignedIn = useIsAuthenticated();
  const id = searchParams.get("id");

  localStorage.setItem("refer", id);

  useEffect(() => {
    if (isSignedIn) {
      const run = async () => {
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/user/${user?.username}`
        );
        console.log(res.data);
        if (res.data)
          return (window.location.href = `http://${process.env.REACT_APP_API_URL}:3000/`);
        else {
          let userData = {
            userId: user.userId,
            username: user.username,
            referredBy: id,
          };
          if (user.email) userData["email"] = user.email;
          const response = await axios.post(
            `http://${process.env.REACT_APP_API_URL}:5005/createUser`,
            userData
          );
          console.log(response.data);
          await axios.post(
            `http://${process.env.REACT_APP_API_URL}:5005/updateUser`,
            {
              username: id,
              query: { $push: { referredUsers: user.username } },
            }
          );
          window.location.href = `http://${process.env.REACT_APP_API_URL}:3000/`;
        }
      };
      run();
    }
  }, [isSignedIn, user, id]);

  return (
    <div className="text-white">
      Registering you under the referrer <br />{" "}
      <strong className="text-orange-500">{id}</strong>
    </div>
  );
};

export default Refer;
