// AppContext.js
import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
} from "react";
// import { useAuthUser } from 'react-auth-kit';
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import axios from "axios";

const AppContext = createContext();

// Define your initial state
const initialState = {
  searchQuery: "",
  selectedProvider: "All",
  selectedSort: "None",
  isTabletScreen: false,
};

// Create a reducer to handle state updates
const searchReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "UPDATE_PROVIDER":
      return { ...state, selectedProvider: action.payload };
    case "UPDATE_SORT":
      return { ...state, selectedSort: action.payload };
    case "UPDATE_COLLECTION":
      return { ...state, selectedCollection: action.payload };
    case "UPDATE_TRAIT":
      return { ...state, selectedTrait: action.payload };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const user = useAuthUser();

  const [balance, setBalance] = useState({});

  const fetchBalance = useCallback(async () => {
    console.log(user);
    const res = await axios.get(
      `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
    );
    console.log(res.data);
    if (res.data) setBalance(res.data.balance);
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBalance();
    }
  }, [isAuthenticated, fetchBalance]);

  console.log(localStorage.getItem("refer"));

  useEffect(() => {
    const run = async () => {
      const referralCode = localStorage.getItem("refer");
      console.log(referralCode);
      if (isAuthenticated && referralCode) {
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
        );
        if (res?.data && !res?.data?.referrdBy) {
          const response = await axios.post(
            `http://${process.env.REACT_APP_API_URL}:5005/set-referrer`,
            {
              referralCode,
              username: user.username,
            }
          );
          if (response.status === 200) {
            console.log("Referrer set successfully");
          }
        }
      }
    };
    // run();
  }, [isAuthenticated, user]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setIsLoggedIn(true);
  //     console.log(user);
  //     if (user) {
  //       console.log("checking if user exists...");
  //       (async () => {
  //         try {
  //           const response = await axios.get(
  //             `http://134.209.147.156:5005/user/${user.username}`
  //           );
  //           if (!response.data) {
  //             await axios.post("http://134.209.147.156:5005/createUser", {
  //               userId: user.id,
  //               username: user.username,
  //               email: user.email,
  //             });
  //           }
  //         } catch (error) {
  //           console.error("Error checking/creating user:", error);
  //         }
  //       })();
  //     }
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }, [isAuthenticated, user]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [isChatBoxCollapsed, setIsChatBoxCollapsed] = useState(false);
  const [prevChatBoxOpen, setPrevChatBoxOpen] = useState(false);
  const [prevSidebarOpen, setPrevSidebarOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isTabletScreen, setIsTabletScreen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
  const [onClickFunctionNext, setOnClickFunctionNext] = useState(null);
  const [onClickFunctionPrev, setOnClickFunctionPrev] = useState(null);
  const [searchState, setSearchState] = useReducer(searchReducer, initialState);
  const [sportsSelectedOption, setSportsSelectedOption] = useState("/home");
  const [selectedOptionCashier, setSelectedOptionCashier] = useState("Deposit");
  const [selectedSport, setSelectedSport] = useState("/soccer");

  useEffect(() => {
    if (isAuthenticated) setIsLoggedIn(true);
  }, [isAuthenticated]);

  const updateMobileScreen = (option) => {
    setIsMobileScreen(option);
  };
  const updateTabletScreen = (option) => {
    setSearchState({ type: "SET_IS_TABLET_SCREEN", payload: option });
    setIsTabletScreen(option);
  };
  const updateCashierOption = (option) => {
    setSelectedOptionCashier(option);
  };
  const updateSelectedSport = (option) => {
    setSelectedSport(option);
  };

  const updateSportsSelectedOption = (newOption) => {
    setSportsSelectedOption(newOption);
  };

  const updateSelectedOption = (option) => {
    setSelectedOption(option);
  };
  const updateSidebar = (option) => {
    setIsSidebarOpen(option);
  };
  const updateChatBox = (option) => {
    setIsChatBoxOpen(option);
  };
  const updateChatBoxCollapsed = (option) => {
    setIsChatBoxCollapsed(option);
  };
  const updatePrevSidebar = (option) => {
    setPrevSidebarOpen(option);
  };
  const updatePrevChatBox = (option) => {
    setPrevChatBoxOpen(option);
  };
  const toggleDropdown = (option) => {
    setOpenDropdown(option);
  };
  const updateLoggedIn = (option) => {
    setIsLoggedIn(option);
  };
  const updateOnClickFunctionNext = (option) => {
    setOnClickFunctionNext(option);
  };
  const updateOnClickFunctionPrev = (option) => {
    setOnClickFunctionPrev(option);
  };
  const updateProvider = (provider) => {
    setSearchState({ type: "UPDATE_PROVIDER", payload: provider });
  };
  const updateSort = (sort) => {
    setSearchState({ type: "UPDATE_SORT", payload: sort });
  };
  const updateCollection = (collection) => {
    setSearchState({ type: "UPDATE_COLLECTION", payload: collection });
  };
  const updateTrait = (trait) => {
    setSearchState({ type: "UPDATE_TRAIT", payload: trait });
  };

  return (
    <AppContext.Provider
      value={{
        balance,
        selectedOption,
        isSidebarOpen,
        isChatBoxOpen,
        openDropdown,
        isLoggedIn,
        onClickFunctionNext,
        onClickFunctionPrev,
        searchState,
        sportsSelectedOption,
        selectedOptionCashier,
        selectedSport,
        isMobileScreen,
        isTabletScreen, //: searchState.isTabletScreen,
        prevSidebarOpen,
        prevChatBoxOpen,
        isChatBoxCollapsed,
        updatePrevSidebar,
        updatePrevChatBox,
        updateMobileScreen,
        updateTabletScreen,
        updateSelectedOption,
        updateSidebar,
        updateChatBox,
        toggleDropdown,
        updateLoggedIn,
        updateOnClickFunctionNext,
        updateOnClickFunctionPrev,
        setSearchState,
        updateProvider,
        updateSort,
        updateCollection,
        updateTrait,
        updateSportsSelectedOption,
        updateCashierOption,
        updateSelectedSport,
        updateChatBoxCollapsed,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
