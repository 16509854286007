import React, { useEffect, useState } from "react";

// assest

import { ReactComponent as BUGER } from "../../../../assets/images/Frame (50).svg";

import { useParams } from "react-router-dom";
import AccountPageTitle from "../Common/AccountPageTitle";
import AccountTabs from "../Common/AccountTabs/AccountTabs";
import { StyleProfile } from "../Profile/styles";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axios from "axios";

const tabs = [
  {
    url: "/account/withdrawals/ALL",
    label: "ALL CHAINS",
  },
  {
    url: "/account/withdrawals/BTC",
    label: "BTC",
  },
  {
    url: "/account/withdrawals/ETH",
    label: "ETH",
  },
  {
    url: "/account/withdrawals/LTC",
    label: "LTC",
  },
  {
    url: "/account/withdrawals/SOL",
    label: "SOL",
  },
  {
    url: "/account/withdrawals/NFT",
    label: "NFT",
  },
];

const Withdrawals = () => {
  const { tab } = useParams();
  const user = useAuthUser();
  const [withdraws, setWithdraws] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const run = async () => {
      try {
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/deposits?userId=${user.userId}`
        );
        if (res.status == 200) setWithdraws(res.data);
      } catch (error) {
        setError(true);
      }
    };
    run();
  });

  if (error) {
    return <h1 className="text-red-400 text-4xl">Unable to load data</h1>;
  }

  const renderTabContent = () => {
    if (tab) {
      return (
        <div style={{ margin: "0 auto", padding: "24px 0px" }}>
          <div
            style={{
              display: "inline-flex",
              padding: "23px 32px",
              alignItems: "flex-start",
              gap: "10px",
              borderRadius: "8px",
              background: "rgba(15, 17, 26, 0.55)",
            }}
          >
            <p
              style={{
                color: "#676D7C",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "21.6px",
                textTransform: "uppercase",
              }}
            >
              {withdraws.length == 0 ? (
                <h1>No Entries</h1>
              ) : (
                withdraws.map((item, index) => <h1>{item}</h1>)
              )}
            </p>
          </div>
        </div>
      );
    } else if (tab === "users") {
      return <div>Content for Referred Users tab</div>;
    } else {
      return <div>Invalid tab selected</div>;
    }
  };
  return (
    <StyleProfile>
      <AccountPageTitle icon={BUGER} title="Withdrawals" />

      <AccountTabs tabs={tabs} />

      {renderTabContent()}
    </StyleProfile>
  );
};

export default Withdrawals;
