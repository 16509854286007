import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { ReactComponent as AK_ORIGINALS } from "../../../assets/images/AK Originals.svg";
import { ReactComponent as BONUS_BATTLES } from "../../../assets/images/Bonus_Battle.svg";
import { ReactComponent as CASINO_IMG } from "../../../assets/images/Frame (9).svg";
import { ReactComponent as GAME_SHOWS } from "../../../assets/images/Hat.svg";
import { ReactComponent as LIVE_CASINO } from "../../../assets/images/Live Casino.svg";
import { ReactComponent as WITH_SIDEBETS } from "../../../assets/images/WithSidebets.svg";
import { ReactComponent as ROULETTE } from "../../../assets/images/Roulette.svg";
import { ReactComponent as BLACKJACK } from "../../../assets/images/Blackjack.svg";
import { ReactComponent as VIDEO_IMG } from "../../../assets/images/Frame (16).svg";
import { StyledNavHeaderBtn, StyledNavigationHeader } from "./styles";
import axios from "axios";
import { ReactSVG } from "react-svg";

// const BUTTONS_CASINO = [
//   { icon: <CASINO_IMG />, label: "Lobby", url: "/casino" },
//   { icon: <AK_ORIGINALS />, label: "Rollbit", url: "/ak-originals" },
//   { icon: <CASINO_IMG />, label: "Slots", url: "/slots" },
//   { icon: <GAME_SHOWS />, label: "Live Games", url: "/live-games" },
//   { icon: <LIVE_CASINO />, label: "Live Casino", url: "/live-casino" },
//   { icon: <WITH_SIDEBETS />, label: "Sidebets", url: "/with-sidebets" },
//   { icon: <BONUS_BATTLES />, label: "Battles", url: "/bonus-battles" },
//   { icon: <ROULETTE />, label: "Roulette", url: "/roulette" },
//   { icon: <BLACKJACK />, label: "Blackjack", url: "/blackjack" },
//   { icon: <VIDEO_IMG />, label: "Video Poker", url: "/video-poker" },
//   { icon: <LIVE_CASINO />, label: "Megaways", url: "/megaways" },
//   { icon: <ROULETTE />, label: "Bonus Buys", url: "/bonusbuys" },
//   { icon: <LIVE_CASINO />, label: "Jackpot", url: "/jackpot" },
// ];

const BUTTONS_NFT = [
  { label: "Lobby", url: "/casino" },
  // { label: "Rollbit", url: "/ak-originals" },
  { label: "Slots", url: "/slots" },
  { label: "Live Games", url: "/live-games" },
  { label: "Live", url: "/live-casino" },
  // { label: "Sidebets", url: "/with-sidebets" },
  { label: "Battles", url: "/bonus-battles" },
  { label: "Roulette", url: "/roulette" },
  { label: "Blackjack", url: "/blackjack" },
  { label: "Video Poker", url: "/video-poker" },
  { label: "Megaways", url: "/megaways" },
];

const NavigationHeader = ({ isNftPage }) => {
  const { selectedOption, updateSelectedOption } = useContext(AppContext);
  const [buttons, setButtons] = useState([]);
  const [srcs, setSrcs] = useState({
    casino_img: "",
    ak_originals: "",
    game_shows: "",
    live_casino: "",
    with_sidebets: "",
    bonus_battles: "",
    roulette: "",
    blackjack: "",
    video_img: "",
  });
  const [error, setError] = useState(false);

  useState(() => {
    const run = async () => {
      try {
        const path = new URLSearchParams();
        path.set(
          "path",
          "/src/components/Common/NavigationHeader/NavigationHeader"
        );
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/images?${path}`
        );
        if (res.status == 200)
          res.data.forEach((e) => {
            // console.log(e);
            srcs[e.name] = e.content;
            setSrcs(srcs);
          });
      } catch (error) {
        setError(true);
      }
    };
    run();
  }, [setSrcs]);

  // if (error) {
  //   return <h1 className="text-red-400 text-4xl">Unable to load</h1>;
  // }

  useEffect(() => {
    const BUTTONS_CASINO = [
      {
        // icon: <ReactSVG src={srcs.casino_img} alt="casino img" />,
        icon: "casino_img",
        label: "Lobby",
        url: "/casino",
      },
      // {
      //   // icon: <ReactSVG src={srcs.ak_originals} alt="ak originals" />,
      //   icon: "ak_originals",
      //   label: "Rollbit",
      //   url: "/ak-originals",
      // },
      {
        // icon: <ReactSVG src={srcs.casino_img} alt="casino img" />,
        icon: "casino_img",
        label: "Slots",
        url: "/slots",
      },
      {
        // icon: <ReactSVG src={srcs.game_shows} alt="game shows" />,
        icon: "game_shows",
        label: "Live Games",
        url: "/live-games",
      },
      {
        // icon: <ReactSVG src={srcs.roulette} alt="roulette" />,
        icon: "roulette",
        label: "Roulette",
        url: "/roulette",
      },
      {
        // icon: <ReactSVG src={srcs.blackjack} alt="blackjack" />,
        icon: "blackjack",
        label: "Blackjack",
        url: "/blackjack",
      },
      {
        // icon: <ReactSVG src={srcs.video_img} alt="video img" />,
        icon: "video_img",
        label: "Video Poker",
        url: "/video-poker",
      },
      {
        // icon: <ReactSVG src={srcs.live_casino} alt="live casino" />,
        icon: "live_casino",
        label: "Jackpot",
        url: "/jackpot",
      },
      {
        // icon: <ReactSVG src={srcs.live_casino} alt="live casino" />,
        icon: "live_casino",
        label: "Megaways",
        url: "/megaways",
      },
      {
        // icon: <ReactSVG src={srcs.roulette} alt="roulette" />,
        icon: "roulette",
        label: "Bonus Buys",
        url: "/bonusbuys",
      },
      // {
      //   // icon: <ReactSVG src={srcs.live_casino} alt="live casino" />,
      //   icon: "live_casino",
      //   label: "Live Casino",
      //   url: "/live-casino",
      // },
      // {
      //   // icon: <ReactSVG src={srcs.with_sidebets} alt="" />,
      //   icon: "with_sidebets",
      //   label: "Sidebets",
      //   url: "/with-sidebets",
      // },
      // {
      //   // icon: <ReactSVG src={srcs.bonus_battles} alt="bonus battles" />,
      //   icon: "bonus_battles",
      //   label: "Battles",
      //   url: "/bonus-battles",
      // },
    ];
    setButtons(isNftPage ? BUTTONS_NFT : BUTTONS_CASINO);
  }, [setButtons, srcs, isNftPage]);

  return (
    <StyledNavigationHeader>
      <div className="content-wrapper no-scrollbar">
        {buttons.map((button, index) => (
          <Link
            to={button.url}
            onClick={() => updateSelectedOption(button.url)}
          >
            <StyledNavHeaderBtn
              className={button.url === selectedOption ? "active" : ""}
            >
              {button.icon && (
                <div
                  className={`btn-icon ${
                    button.url === selectedOption ? "active-icon" : ""
                  }`}
                >
                  <ReactSVG src={srcs[button.icon]} />
                  {/* {button.icon} */}
                </div>
              )}
              {button.label}
            </StyledNavHeaderBtn>
          </Link>
        ))}
      </div>
    </StyledNavigationHeader>
  );
};

export default NavigationHeader;
