import React, { useEffect, useState } from "react";
import SectionHeader from "../SectionHeader/SectionHeader";

import { ReactComponent as CASINO } from "../../../assets/images/Frame (9).svg";
import CASINO_IMG_1 from "../../../assets/images/IMAGE (14).png";
import CASINO_IMG_2 from "../../../assets/images/IMAGE (15).png";
import CASINO_IMG_3 from "../../../assets/images/IMAGE (16).png";
import CASINO_IMG_4 from "../../../assets/images/IMAGE (17).png";
import CASINO_IMG_5 from "../../../assets/images/IMAGE (18).png";
import CASINO_IMG_6 from "../../../assets/images/IMAGE (19).png";
import useSlider from "../../../hooks/useSlider";
import HoverableImgMainHome from "../HoverableImage/HoverableImgMainHome";
import Slider from "../Slider/Slider";
import { StyledCasinoSection } from "./StyledCasinoSection";
import { Link } from "react-router-dom";
import axios from "axios";

// const casinoImages = [
//   CASINO_IMG_1,
//   CASINO_IMG_2,
//   CASINO_IMG_3,
//   CASINO_IMG_4,
//   CASINO_IMG_5,
//   CASINO_IMG_1,
//   CASINO_IMG_6,
//   CASINO_IMG_1,
//   CASINO_IMG_2,
//   CASINO_IMG_3,
//   CASINO_IMG_4,
//   CASINO_IMG_5,
//   CASINO_IMG_1,
//   CASINO_IMG_6,
// ];

const casinoLink = [
  "p0_p0-sweet-bonanza",
  "hs_hs-wanted-dead-or-a-wild",
  "p0_p0-gates-of-olympus",
  "p0_p0-fruit-party",
  "p0_p0-sugar-rush-summer-time",
  "p0_p0-sweet-bonanza",
  "gs_gs-plinko",
  "p0_p0-sweet-bonanza",
  "hs_hs-wanted-dead-or-a-wild",
  "p0_p0-gates-of-olympus",
  "p0_p0-fruit-party",
  "p0_p0-sugar-rush-summer-time",
  "p0_p0-sweet-bonanza",
  "gs_gs-plinko",
];

const CasinoSection = () => {
  const { containerRef, scrollLeft, scrollRight } = useSlider();
  const [casinoImages, setCasinoImages] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const run = async () => {
      try {
        // const path = new URLSearchParams();
        // path.set("path", "/src/components/Common/CasinoSection/CasinoSection");
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/filter?sort=plays&order=-1`
        );
        console.log(res.data);
        setCasinoImages(res.data);
        // console.log(casinoImages);
      } catch (error) {
        setError(true);
      }
    };
    run();
  }, [setCasinoImages, setError]);

  if (error) {
    return <h1 className="text-4xl text-red-400">Unable to load the page</h1>;
  }
  // console.log(user.);
  // const [link, setLink] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     if (isLoaded) {
  //       const res = await axios.post(`http://${process.env.REACT_APP_API_URL}:5005/getGame`, {
  //         username: user.username,
  //         password: "Ben1441#",
  //         gameId: "129687",
  //       });
  //       setLink(res.data.response);
  //     }
  //   })();
  // }, [isLoaded]);

  const CardsComponent = casinoImages.map((image, index) => {
    return (
      <Link to={`/casino/games/${image.id_hash}`}>
        <HoverableImgMainHome
          key={index}
          src={image.image_portrait}
          alt={`casino-${index}`}
        />
      </Link>
    );
  });

  return (
    <StyledCasinoSection>
      <SectionHeader
        iconHeader={CASINO}
        casinoText="CASINO"
        sideButton="View All"
        sideButtonLink="/casino"
        hasRecommended={true}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
      />

      <Slider CardsComponent={CardsComponent} containerRef={containerRef} />
    </StyledCasinoSection>
  );
};

export default CasinoSection;
