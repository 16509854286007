import React, { useCallback, useEffect, useState } from "react";

//assets
// import { ReactComponent as MONEY } from "../../../../assets/images/Frame (53).svg";
// import { ReactComponent as SWITCH } from "../../../../assets/images/Frame (55).svg";
import { ReactComponent as CIRCLE } from "../../../../assets/images/Frame 507.svg";
import COIN from "../../../../assets/images/IMAGE (47).png";
import AccountPageTitle from "../Common/AccountPageTitle";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axios from "axios";
// import { StyleProfile } from "../Profile/styles";

const BalanceCard = () => {
  const isAuthenticated = useIsAuthenticated();
  const user = useAuthUser();

  const [balance, setBalance] = useState(0);

  const getBalance = useCallback(async () => {
    const response = await axios.get(
      `http://${process.env.REACT_APP_API_URL}:5005/user/${user.username}`
    );
    if (response.data) {
      setBalance(response.data.balance[response.data.balance.selected]);
    }
  });

  useEffect(() => {
    if (isAuthenticated) getBalance();
  }, [isAuthenticated, getBalance]);
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "40px 16px",
          marginTop: "20px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "10px",
          background: "rgba(15, 17, 26, 0.55)",
          height: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            margin: "-20px 0",
          }}
        >
          <div>
            <CIRCLE />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
            }}
          >
            <p
              style={{
                width: "32px",
                color: "#fff",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16.8px",
              }}
            >
              Main
            </p>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <img
                src={COIN}
                alt="CIRCLE"
                style={{ width: "17px", height: "16px" }}
              />
              <p
                style={{
                  width: "39px",
                  color: "#fff",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "16.8px",
                }}
              >
                ${balance}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceCard;
