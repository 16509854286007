import React from "react";
import { StyledCardMessage } from "./styles";

const CardMessage = ({ rankIcon, playerName, messageText }) => {
  return (
    <StyledCardMessage>
      <div class="container-name" size="13">
        <div className="flex flex-row gap-2">
          <div class="rank">
            <img src={rankIcon} alt="" width={20} height={20} />
          </div>
          <div class="name">{playerName}:</div>
        </div>
      </div>
      <span class="message ml-8">{messageText}</span>
    </StyledCardMessage>
  );
};

export default CardMessage;
