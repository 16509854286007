import React, { useContext } from "react";

//assets
import BalanceCard from "./MainBalanceCard";
import GMFBalancecard from "./GMFBalancecard";
import { ReactComponent as MONEY } from "../../../../assets/images/Frame (53).svg";
import AccountPageTitle from "../Common/AccountPageTitle";
import { StyleProfile } from "../Profile/styles";
import { AppContext } from "../../../../AppContext";

const BalancesPage = () => {
  const { balance } = useContext(AppContext);
  return (
    <StyleProfile>
      <AccountPageTitle icon={MONEY} title="Balances" />

      <div className="section-container">
        <div className="title-container">
          <h3 className="section-title" style={{ margin: "0" }}>
            Balances
          </h3>
        </div>
        <div class="subtitle-container">
          <div class="total">
            Total Balance USD + GMF:
            <span>${parseFloat(balance.usdt + balance.gmf)}</span>
          </div>
        </div>
        <div>
          <BalanceCard />
        </div>
        <div>
          <GMFBalancecard />
        </div>
      </div>
    </StyleProfile>
  );
};

export default BalancesPage;
