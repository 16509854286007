import React, { useState } from "react";
import { StyledHoverableImage } from "./styles";

const HoverableImage = ({ src, alt, link }) => {
  const [error, setError] = useState(false);
  return (
    !error && (
      <div>
        <StyledHoverableImage>
          <a href={link}>
            <img
              src={src}
              alt={alt}
              className="image-card"
              // onError={(e) => setError(true)}
            />
          </a>
        </StyledHoverableImage>
      </div>
    )
  );
};

export default HoverableImage;
