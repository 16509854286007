import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../AppContext";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

const Sports = () => {
  const { sportsSelectedOption, isMobileScreen } = useContext(AppContext);
  const [link, setLink] = useState("");
  const user = useAuthUser();
  const isLoaded = useIsAuthenticated();

  useEffect(() => {
    const run = async () => {
      console.log("isMobileScreen ", isMobileScreen);
      const res = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/getGame`,
        {
          username: user.username,
          password: "Ben1441#", //user.password || "Gamify@123",
          gameId: isMobileScreen ? "143491" : "143489",
        }
      );
      if (res.data.error == 1) {
        throw new Error("Error fetching sportsbook:\n" + res.data.message);
        return;
      }
      setLink(res.data.response);
    };
    if (isLoaded && user) run();
  }, [setLink, isMobileScreen, isLoaded, user]);

  return (
    <iframe
      src={link}
      width={1000}
      height={600}
      className="w-full h-screen"
      title="Sportsbook"
    ></iframe>
    // <StyledSportsContainer>
    //   {sportsSelectedOption === "/home" || sportsSelectedOption === "" ? (
    //     <>
    //       <SportsBannerImage />
    //       <SportSection titleIcon={<TOP_MATCHES />} hasBigContainer={true} />
    //       <SportSection
    //         titleIcon={<LIVE_MATCHES />}
    //         isLive={true}
    //         hasBigContainer={true}
    //       />
    //       <SportSection titleIcon={<LIVE_MATCHES />} />
    //     </>
    //   ) : (
    //     <NoSportFound />
    //   )}
    //   <FooterSports />
    // </StyledSportsContainer>
  );
};

export default Sports;
